import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { EyeIcon, EyeOffIcon, FingerPrintIcon, CheckIcon } from '@heroicons/react/solid'
import { useForm } from 'react-hook-form'
import { debounce } from 'throttle-debounce'
import Loading from '@/shared/Loading'

import Card from '@/shared/Card'
import Modal from '@/shared/Modal'
import { useGlobalState } from '@/state'
import { randomToastSuccess } from '@/shared/Toast'

import PublicSubmissionRow from '@/pages/Users/PublicProfile/PublicSubmissionRow'
import useQuery from '@/hooks/useQuery'
import PrimaryButton from '@/shared/Buttons/Primary'
import DefaultButton from '@/shared/Buttons/Default'

const PreviewForm = ({ submission }) => {
  const [, setToast] = useGlobalState('toast')
  const [token, setToken] = useState(null)
  const [renderingVideo, setRenderingVideo] = useState(false)
  const { putpostRequest, getRequest } = useQuery()
  const pollRef = useRef(0)
  console.log(submission)

  getRequest(`/api/v3/auditions/${submission.id}/videomancy_preview_form`, {}, (err, jsonData) => {
    if (err) { /* hook */ }
    setToken(jsonData.token)
  })

  const renderVideo = debounce(300, (data) => {
    putpostRequest(`/api/v3/auditions/${submission.id}/render_videomancy_video`, 'POST', {}, (err, jsonData) => {
      if (err) { return console.log(err) }

      setRenderingVideo(true)
      setTimeout(poll, 3000)
    })
  })

  const poll = () => {
    pollRef.current += 1
    getRequest(`/api/v3/auditions/${submission.id}`, {}, (err, jsonData) => {
      if (err) { return }
      if (pollRef.current > 100) {
        setToast('Code 241: Could not render video. I will call the police. This is not right.')
        return
      }
      if (jsonData.submission.videomancyStatus === 'render_finished') {
        window.location.reload()
      } else {
        setTimeout(poll, 3000)
      }
    })
  }


  if (!token) return <Loading />

  if (renderingVideo) {
    return <div className='flex flex-col gap-y-2 items-center max-w-96 mx-auto justify-center bg-cccblue bg-opacity-10 p-4 rounded-2xl'>
      <Loading noMessage noLoadingMessage />
      <div className='text-sm'>Rendering video now. This usually takes about 3 minutes.</div>
      <div className='text-sm'>Jeopardy music.. 🎵</div>
      <div className='text-sm'>You can safely leave the page if you want. Or you can wait here. Either way just know that I hope you're doing great.</div>
    </div>
  }

  return <div className='flex flex-col gap-y-2 items-center mx-auto w-full justify-center bg-cccblue bg-opacity-10 p-4 rounded-2xl'>
    <iframe src={`https://www.videomancy.com/videos/${submission.videomancyUuid}/preview?show_editor=true&allowed_dimensions=1920x1080&token=${token}`} width="100%" height="700" frameBorder="0" allowFullScreen></iframe>
    <div className='flex gap-x-4 justify-center items-center mt-2'>
      <button onClick={renderVideo} class="flex justify-center space-x-2 items-center px-8 py-4 shadow-sm text-sm font-medium rounded-md text-white bg-cccblue hover:text-white hover:bg-cccblue-alt dark:hover:bg-cccblue-alt dark:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">Render Video!</button>
    </div>
  </div>
}

const VideomancyPrompt = ({initialSubmission}) => {
  const [, setToast] = useGlobalState('toast')
  const [currentUser] = useGlobalState('currentUser')
  const [makingVideo, setMakingVideo] = useState(false)
  const [submission, setSubmission] = useState(initialSubmission)
  const [loading, setLoading] = useState(false)
  const { putpostRequest, getRequest } = useQuery()
  const [sampleSubmission, setSampleSubmission] = useState(null)
  const sampleSubmissionId = 2662322 // used to show a sample submission that looks good already
  const pollRef = useRef(0)

  if (!currentUser) return null
  if (currentUser.id !== submission.userId) return null

  const goToSubmissionShow = () => {
    window.location.href = `/submissions/${submission.id}`
  }

  useEffect(() => {
    getRequest(`/api/v3/auditions/${sampleSubmissionId}`, {}, (err, jsonData) => {
      if (err) { /* hook */ }
      setSampleSubmission(jsonData.submission)
    })
  }, [])

  const makeVideo = debounce(300, (data) => {
    if (!currentUser.canMakeVideomancyVideos) {
      return setToast('This feature is only available to CCC Premium members.')
    }

    putpostRequest(`/api/v3/auditions/${submission.id}/create_videomancy_video`, 'POST', {}, (err, jsonData) => {
      if (err) { return console.log(err) }

      setMakingVideo(true)
      setTimeout(poll, 3000)
    })
  })

  const poll = () => {
    pollRef.current += 1
    getRequest(`/api/v3/auditions/${submission.id}`, {}, (err, jsonData) => {
      if (err) { return }
      if (pollRef.current > 20) {
        setToast('Code 239: Could not create video. Are you sure this submission has spoken words?')
        return
      }
      if (jsonData.submission.videomancyStatus === 'captions_finished') {
        setSubmission(jsonData.submission)
      } else {
        setTimeout(poll, 3000)
      }
    })
  }

  if (submission.kind !== 'Voice Actor' && submission.kind !== 'Singer') return null

  if (!sampleSubmission) return null

  if (submission.videomancyStatus === 'captions_finished') {
    return <PreviewForm submission={submission} />
  }

  if (makingVideo) {
    return <div className='flex flex-col gap-y-2 items-center max-w-xl mx-auto justify-center bg-cccblue bg-opacity-10 p-4 rounded-2xl'>
      <Loading noMessage noLoadingMessage />
      <div className='text-sm'>Creating your video... Gimme just a sec.</div>
    </div>
  }

  return <div className='flex flex-col gap-y-2 items-center max-w-xl mx-auto justify-center bg-cccblue bg-opacity-10 p-4 rounded-2xl'>
    <h3 className='text-2xl font-bold text-center'>Turn your submission into a video</h3>
    <div className='text-sm max-w-96 text-gray-700 dark:text-gray-200'>If you want to make your submission <i className='bg-cccorange px-1 text-white rounded'>stand out</i>, you can turn it into a video. CCC will show the video on your submission.</div>
    <div className='text-sm max-w-96 text-gray-700 dark:text-gray-200'>Here is a sample of one I made for me: </div>

    <ul>
      { !submission.videoUrl && <PublicSubmissionRow image='user' commentsOpen={false} submission={sampleSubmission} voteIds={[]} /> }
      { submission.videoUrl && <PublicSubmissionRow image='user' commentsOpen={false} submission={submission} voteIds={[]} /> }
    </ul>
    { !submission.videoUrl && <PrimaryButton disabled={!currentUser.canMakeVideomancyVideos} onClick={makeVideo} className='text-xl font-semibold py-6 px-8' loading={loading} text='Preview My Video' /> }

  </div>
}

export default VideomancyPrompt

VideomancyPrompt.propTypes = {
  initialSubmission: PropTypes.object.isRequired
}
